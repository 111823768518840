const { initializeApp } = require('firebase/app');
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage} from "firebase/storage";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_apiKey,
    authDomain: process.env.REACT_APP_authDomain,
    // databaseURL: process.env.REACT_APP_databaseURL,
    projectId: process.env.REACT_APP_projectId,
    storageBucket: process.env.REACT_APP_storageBucket,
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
    appId: process.env.REACT_APP_appId
};

const app = initializeApp(firebaseConfig);

export const storage = getStorage();

export const db = getFirestore(app);

// if(process.env.REACT_APP_ENV === "dev") {
//     connectFirestoreEmulator(db, 'localhost', 8080);
// }
