import logoOpensea from '../img/logo_opensea.png';
import logoHighRes from '../img/highres.png';
import logoNoBg from '../img/no-bg-icon.png';
import logoPortal from '../img/hypnose.png';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from 'react-router-dom';
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from './firebase';
import { saveAs } from 'file-saver';

const contractAddress = "0xd20e1b2a23f48f54a7193b69b7c982e1e1dcd94f";

const downloadTAAC = (tokenID) => {
    const imgurl = `taacs/${tokenID}.png`;

    console.log(imgurl);
    getDownloadURL(ref(storage, imgurl))
        .then((url) => {
            fetch(url)
                .then((response) => response.blob())
                .then((blob) => {
                    try {
                        saveAs(blob, `${tokenID}.png`);
                    } catch (error) {
                        // if the saveAs method is not supported by the browser, open the image in a new tab
                        window.open(url);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        })
        .catch((error) => {
            console.log(error);
        });
}

const downloadTAACNoBg = (tokenID) => {
    const imgurl = `noBg/${tokenID}.png`;

    console.log(imgurl);
    getDownloadURL(ref(storage, imgurl))
        .then((url) => {
            fetch(url)
                .then((response) => response.blob())
                .then((blob) => {
                    try {
                        saveAs(blob, `${tokenID}-noBg.png`);
                    } catch (error) {
                        // if the saveAs method is not supported by the browser, open the image in a new tab
                        window.open(url);
                    }
                    
                })
                .catch((error) => {
                    console.log(error);
                    window.open(imageUrl);
                });
        })
        .catch((error) => {
            console.log(error);
        });
}

function MyTaacsLinks(props) {

    const level0Tooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Reach Level 1 to unlock this feature
        </Tooltip>
    );


    const portalComponent = () => {
        if(!props.isOwner) return
        if(props.level > 0) {
            return <Link to={'/Portal'} state={{ tokenId: props.tokenId }}>
                    <img className="portal-butoon-logo" src={logoPortal} alt="Portal" />
                </Link>
        } else {
            return <a className="disabled-img">
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={level0Tooltip}
                >
                    <img className="portal-butoon-logo " src={logoPortal} alt="Portal" />
                </OverlayTrigger>
            </a>
        }
        
    }

    return (<div className='card_buttons'>
            <a href={`https://opensea.io/assets/ethereum/${contractAddress}/${props.tokenId}`} target="_blank">
                <img src={logoOpensea} alt='Opeansea Logo'></img>
            </a>
            {props.isOwner ? 
                <div className="download-button">
                    <img src={logoHighRes} alt='High Res' onClick={() => downloadTAAC(props.tokenId)}></img>
                </div> : null
            }

            {props.isOwner ? 
                <div className="download-button">
                    <img src={logoNoBg} alt='No Background' onClick={() => downloadTAACNoBg(props.tokenId)}></img>
                </div>: null
            }
            
            
            {portalComponent()}
        </div>);
}

export default MyTaacsLinks;