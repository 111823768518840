import React from 'react';

class NotFound extends React.Component {

    render() {
        return (
            <div className='not-found-page '>
                <h1>404 What's happening??...NGMI!</h1>
            </div>
        );
    }
}

export default NotFound;