import React from 'react'


class ClearChakraPopup extends React.Component {
    render() {
        return (
            <div className='popup'>
                <div className='popup_inner'>
                    <h1>{this.props.text}</h1>
                    {this.props.children}
                    
                </div>
            </div>
        );
    }
}

export default ClearChakraPopup;