import { Web3Button } from "@web3modal/react";

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAccount, chain, useNetwork } from 'wagmi';
const { Network, Alchemy } = require('alchemy-sdk');
import { Alert } from 'react-bootstrap';
import refreshIcon from '../img/refresh-icon.png';
import axios from 'axios';
import { CircleSpinnerOverlay } from 'react-spinner-overlay'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LevelBadge from "./LevelBadge";
import { db } from './firebase';
import { doc, getDoc } from "firebase/firestore";
import MyTaacsLinks from "./MyTaacsLinks";
import Dragonballs from "./Dragonballs";


const contractAddress = "0xd20e1b2a23f48f54a7193b69b7c982e1e1dcd94f";
const alchemyKey = process.env.REACT_APP_ALCHEMY_API_KEY;

const alchemySettings = {
    apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
    network: Network.ETH_MAINNET,
};

const alchemy = new Alchemy(alchemySettings);

function replaceRange(s, start, end, substitute) {
    return s.substring(0, start) + substitute + s.substring(end);
}

const refreshMetadata = async (tokenId) => {
    const res = await axios.get(`https://eth-mainnet.g.alchemy.com/nft/v2/${alchemyKey}/getNFTMetadata`, { params: { contractAddress: contractAddress, tokenId: tokenId, refreshCache: true } });
    toast("Updated Metadata, try to refresh the page!");
}

const connectedWalletLabel = (isConnected, address) => {
    if (isConnected) {
        return <span className='mint-connected-wallet-text'><b>Connected:</b> {replaceRange(address, 6, 38, "...")}</span>
    }
}

const renderConnectButton = (isConnected, nfts) => {
    if (!isConnected) {
        return <div className="power-chamber-connect"><Web3Button /></div>
    } else {
        if (!nfts || nfts.length == 0) {

            return <Web3Button />
        }
    }
}

const renderCards = (address, nfts, isConnected, level) => {
    if (!isConnected || address === null || nfts === null) return

    return <ul className='cards'>
        {
            nfts.map((item) => {
                return <NFTCard key={item.tokenId} title={item.title} description='description' image={item.rawMetadata.image} tokenId={item.tokenId} level={level} />
            })
        }
    </ul>
}

const levelBadge = (userLevel, isConnected, address) => {
    if (!isConnected || !address) return
    return <LevelBadge level={userLevel} />
}

const errorAlert = (isConnected, nfts) => {
    if (isConnected && !nfts || isConnected && nfts.length == 0) {
        return <Alert className='alert-error' key='danger' variant='danger'>
            "If you don't share someone's pain, you can never understand them" - Nagato (Natuto) We share your pain, you currently have no TAACs.
            (Naruto)
        </Alert>
    }
}

const renderTitle = (isConnected, nfts) => {
    if (isConnected) {
        if (!nfts) {
            return <h1>You have no TAACs, mint your TAACs now!</h1>
        }
        return <h1>My TAACs ({nfts ? nfts.length : ""})</h1>
    } else {
        return <h1>Please connect your wallet</h1>
    }
}

const dragonballsComponent = (isConnected, dragonballs, level) => {
    if (isConnected) {
        let levelballs = dragonballsForLevel((level ?? 0))
        let countDragonballs = (dragonballs ?? 0) + levelballs;
        console.log("countDragonballs", countDragonballs);
        return <Dragonballs ballcount={countDragonballs} />
    }

}

const dragonballsForLevel = (level) => {
    let total = 0;
    for (let i = 1; i <= level; i++) {
        total += i;
    }
    return total;
}


function UserPage2() {

    const [isLoading, setIsLoading] = useState(false);
    const [nfts, setNfts] = useState(null);
    const [userLevel, setUserLevel] = useState(0);
    const [collectedDragonballs, setCollectedDragonballs] = useState(0);

    const { address, isConnected } = useAccount();


    const fetchCollection = async () => {
        if (!isConnected || !address) return
        console.log("fetch for: ", address);
        setIsLoading(true);

        let fetchedNfts = [];
        let pageKey = null;

        // fetch tokenID owned by user, use pagekey if more then 100
        do {
            let query = { contractAddresses: [contractAddress] }
            if (pageKey !== null) {
                query["pageKey"] = pageKey
            }
            let nfts = await alchemy.nft.getNftsForOwner(address, query);
            fetchedNfts.push(nfts.ownedNfts);
            pageKey = nfts.pageKey !== undefined ? nfts.pageKey : null;
        } while (pageKey !== null);

        // map all tokenIDs to an array

        fetchedNfts = fetchedNfts.flatMap(x => x);
        // console.log(fetchedNfts);

        setNfts(fetchedNfts);

        setIsLoading(false);
    };

    const fetchUserData = async () => {
        if (!isConnected || !address) return
        const docRef = doc(db, "users", address.toLowerCase());
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setUserLevel(docSnap.data().level)
            return docSnap.data().level;
        } else {
            return null
        }
    }

    const fetchDragonballs = async () => {
        if (!isConnected || !address) return
        const docRef = doc(db, "dragonballs", address.toLowerCase());
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setCollectedDragonballs(docSnap.data().count)
            return docSnap.data().count;
        } else {
            return null
        }
    }

    useEffect(() => {
        fetchCollection();
        fetchUserData();
        fetchDragonballs();
    }, [isConnected, address]);

    return (
        <div className='user-page'>
            <CircleSpinnerOverlay
                loading={isLoading}
                overlayColor="rgba(252, 203, 244, 0.8)"
                color="rgba(19, 54, 108)"
                message={
                    <p style={{ marginTop: "12px", color: "rgba(19, 54, 108)", fontWeight: 800 }}>
                        is loading...
                    </p>
                }
            />
            <div className="user-page-header">

                <div className="user-page-header-title-container">
                    {renderTitle(isConnected, nfts)}
                    {connectedWalletLabel(isConnected, address)}
                </div>
                <div className='user-page-badge'>
                    {levelBadge(userLevel, isConnected, address)}
                    {/* {dragonballsComponent(isConnected, collectedDragonballs, userLevel)} */}
                </div>

            </div>

            <div className="user-page-button-container">
                {renderConnectButton(isConnected, nfts)}
                {errorAlert(isConnected, nfts)}
            </div>

            {renderCards(address, nfts, isConnected, userLevel)}
            <ToastContainer
                position="top-center"
                theme="colored"
            />

        </div>
    );
}

function NFTCard(props) {

    return <li className="cards_item">
        <div className="card">
            <div className="card_image">
                <Link to={`/detail/${props.tokenId}`}>
                    <img src={props.image} />
                </Link>
                {/* <img src={props.image} /> */}
            </div>
            <div className="card_content">
                <div className="card_header">
                    <h2 className="card_title">{props.title}</h2>
                    <div className="refresh-button">
                        <img src={refreshIcon} alt='refresh' onClick={() => refreshMetadata(props.tokenId)}></img>
                    </div>
                </div>
                <MyTaacsLinks tokenId={props.tokenId} level={props.level} isOwner={true}/>
            </div>
        </div>
    </li>;
}

export default UserPage2;