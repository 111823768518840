import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAccount } from 'wagmi';
import MyTaacsLinks from './MyTaacsLinks';
import { Web3Button } from "@web3modal/react";
import { db } from './firebase';
import { doc, getDoc } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import tshirt from '../img/tshirt.png';
import eyes from '../img/sunglasses.png';
import mouth from '../img/smiling.png';
import earrrings from '../img/earrings.png';
import hat from '../img/pilot-hat.png';
import fur from '../img/monkey.png';
import landscape from '../img/landscape.png'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Button } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import ClearChakraPopup from './ClearChakraPopup';
import { useSignMessage } from 'wagmi'
import axios from 'axios';
import { CircleSpinnerOverlay } from 'react-spinner-overlay'


import arrowRight from '../img/right-arrows.png';
import chakrabadge0 from '../img/chakra0.png';
import chakrabadge1 from '../img/chakra1.png';
import chakrabadge2 from '../img/chakra2.png';
import chakrabadge3 from '../img/chakra3.png';
import chakrabadge4 from '../img/chakra4.png';
import chakrabadge5 from '../img/chakra5.png';
import chakrabadge6 from '../img/chakra6.png';
import chakrabadge7 from '../img/chakra7.png';

import { storage } from './firebase';
import { async } from '@firebase/util';
const { Network, Alchemy } = require('alchemy-sdk');


function replaceRange(s, start, end, substitute) {
    return s.substring(0, start) + substitute + s.substring(end);
}

const alchemyKey = process.env.REACT_APP_ALCHEMY_API_KEY;

const alchemySettings = {
    apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
    network: Network.ETH_MAINNET,
};

const alchemy = new Alchemy(alchemySettings);

const xpPerClearance = 5000;

function TAACDetail(props) {

    const alchemySettings = {
        apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
        network: Network.ETH_MAINNET,
    };


    const contractAddress = "0xd20e1b2a23f48f54a7193b69b7c982e1e1dcd94f";

    const alchemy = new Alchemy(alchemySettings);

    const { tokenId } = useParams();

    const { address, isConnected } = useAccount();

    const [metadata, setMetadata] = useState(null);
    const [level, setLevel] = useState(0);
    const [nonce, setNonce] = useState(null);
    const [xp, setXP] = useState(0);
    const [addressIsOwner, setAddressIsOwner] = useState(false);
    const [chakraClearance, setChakraClearance] = useState(null);
    const [successfullyCleared, setSuccessfullyCleared] = useState(false);
    const [errorWhileClearing, setErrorWhileClearing] = useState(false);

    const [showChakraPopup, setShowChakraPopup] = useState(false);

    const { data, isError, isLoading, isSuccess, signMessage } = useSignMessage({
        message: nonce,
        async onSuccess(data) {
            console.log('Success', data)
            // clearChakra POST request with data as signiture, address and tokenID
            // this will deduct xp from User and make new Chakra order
            // nonce will change as well so we need to refetch user or clearChakra returns new nonce even if error

            console.log("Data: ", data);

            let response = await axios.post("https://us-central1-taac-react.cloudfunctions.net/clearChakra", {
                address: address.toLowerCase(),
                signature: data,
                tokenId: tokenId
            });

            console.log(response);

            if (response.status === 200) {
                console.log("Success, status code 200");
                setShowChakraPopup(false);
                setSuccessfullyCleared(true);
                setErrorWhileClearing(false);
                setChakraClearance(chakraClearance + 1);
            } else {
                console.log("Error, status code: ", response.status);
                setShowChakraPopup(false);
                setSuccessfullyCleared(false);
                setErrorWhileClearing(true);
                // do your error logic here
            }


        },
        onError(error) {
            console.log('Error', error);
        },
    })

    const connectedWalletLabel = () => {
        if (isConnected) {
            return <span className='mint-connected-wallet-text'><b>Connected:</b> {replaceRange(address, 6, 38, "...")}</span>
        } else {
            return <Web3Button />
        }
    }

    const downloadTAACNoBg = (tokenID) => {
        if (!tokenID) return

        const imgurl = `noBg/${tokenID}.png`;

        getDownloadURL(ref(storage, imgurl))
            .then((url) => {
                fetch(url)
                    .then((response) => response.blob())
                    .then((blob) => {
                        const objectURL = URL.createObjectURL(blob);
                        document.querySelector('.taac-detail-card-image img').src = objectURL;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    }


    const taacImg = () => {
        if (!metadata) return
        return <div className='taac-detail-card-image'><img></img></div>
    }


    const getTraitImage = (trait) => {
        switch (trait) {
            case "Eyes": return eyes;
            case "Mouth": return mouth;
            case "Clothes": return tshirt;
            case "Earring": return earrrings;
            case "Hat": return hat;
            case "Fur": return fur;
            case "Background": return landscape;
        }
    }

    const getChakraBadge = (clearance) => {
        if(clearance === null) return chakrabadge0;
        switch (clearance) {
            case 0: return chakrabadge0;
            case 1: return chakrabadge1;
            case 2: return chakrabadge2;
            case 3: return chakrabadge3;
            case 4: return chakrabadge4;
            case 5: return chakrabadge5;
            case 6: return chakrabadge6;
            case 7: return chakrabadge7;
        }
    }

    const traitComponent = () => {
        if (!metadata) return
        return (<div className="detail-items-list">
            {metadata.attributes.map((item, index) => (
                <div className="detail-item" key={index}>
                    <img src={getTraitImage(item.trait_type)}></img>
                    <div className='detail-item-value-container'>
                        <div className="detail-key">{item.trait_type}</div>
                        <div className="detail-value">{item.value}</div>
                    </div>
                </div>
            ))}
        </div>)
    }

    const taacLinks = () => {
        if (!isConnected || !address || !tokenId) return
        return <div className='taac-detail-card-links-container'><MyTaacsLinks tokenId={tokenId} level={level} isOwner={addressIsOwner} /></div>
    }

    const chakraTooltip = (props) => (
        <Tooltip  id="button-tooltip" {...props}>
            Your current chakra level is {chakraClearance}, {chakraClearance >= 7 ? "which is max you can go" : ""}
        </Tooltip>
    );

    const notEnoughXPTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            You don't have enough XP to clear your next Chakra level
        </Tooltip>
    );

    const allChakraBadges = () => {
        if(chakraClearance === null || chakraClearance === 0) return 
        return (<div className='all-chakras'>
            {
                [...Array(chakraClearance).keys()].map(item => {
                    console.log('item:', item);
                    return <img key={item} src={getChakraBadge(item+1)}></img>
                })
            }
        </div>)
    }

    const chakraComponent = () => {
        if (!address || !isConnected || !addressIsOwner) return
        if (chakraClearance == null) {
            return
        }
        return <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={chakraTooltip}
        >
            {allChakraBadges()}
        </OverlayTrigger>
    }

    const onClearChakraButtonAction = () => {
        setShowChakraPopup(true);
    }

    const clearChakra = () => {
        // sign message

        signMessage();

    }

    const onClosePopUp = () => {
        setShowChakraPopup(false);
    }

    const clearChakraButton = () => {
        if (!address || !isConnected || !addressIsOwner || chakraClearance >= 7) return
        if (xp < xpPerClearance) {
            return <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={notEnoughXPTooltip}
            >
                <Button size="lg" variant="primary" className="custom-btn disabled-button">Clear Chakra</Button>
            </OverlayTrigger>
        }
        return <Button size="lg" variant="primary" className="custom-btn" onClick={onClearChakraButtonAction}>Clear Chakra</Button>
    }

    const clearNowButton = () => {
        if (xp < xpPerClearance) {
            return <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={notEnoughXPTooltip}
            >
                <Button size="lg" variant="primary" className="custom-btn disabled-button">Clear now</Button>
            </OverlayTrigger>
        }
        return <Button size="lg" variant="primary" className="custom-btn" onClick={clearChakra}>Clear now</Button>
    }

    const fetchMetadata = async () => {
        if (address && isConnected && tokenId) {
            const data = await alchemy.nft.getNftMetadata(
                contractAddress,
                tokenId
            )
            setMetadata(data.rawMetadata);
        }
    }

    const fetchUserData = async () => {
        if (!isConnected || !address) return
        const docRef = doc(db, "users", address.toLowerCase());
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setLevel(docSnap.data().level);
            setXP(docSnap.data().xp);
            setNonce(docSnap.data().nonce);
            return docSnap.data().level;
        } else {
            return null
        }
    }

    const fetchOwnership = async () => {
        if (!isConnected || !address) return
        console.log("fetchOwnership");
        let owners = (await alchemy.nft.getOwnersForNft(contractAddress, tokenId));
        setAddressIsOwner(owners.owners.map(item => item.toLowerCase()).includes(address.toLowerCase()));
    }

    const fetchChakraClearance = async () => {

        if (!isConnected || !address || !addressIsOwner || !tokenId) return
        console.log("fetchChakraClearance");
        const chakrasId = address.toLowerCase() + tokenId;
        const docRef = doc(db, "chakras", chakrasId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setChakraClearance(docSnap.data().clearance);
        } else {
            setChakraClearance(null);
        }

    }

    useEffect(() => {
        fetchMetadata();
        downloadTAACNoBg(tokenId);
        fetchOwnership();

    }, [tokenId, address]);

    useEffect(() => {
        fetchChakraClearance();
    }, [addressIsOwner]);

    useEffect(() => {
        fetchUserData();
    }), [address];

    return (
        <div className="taac-detail-page">
            <CircleSpinnerOverlay
                loading={isLoading}
                overlayColor="rgba(252, 203, 244, 0.8)"
                color="rgba(19, 54, 108)"
                message={
                    <p style={{ marginTop: "12px", color: "rgba(19, 54, 108)", fontWeight: 800 }}>
                        is loading...
                    </p>
                }
            />
            <h1>MyTAAC</h1>
            {connectedWalletLabel()}
            {
                isConnected ? <div className='taac-detail-card'>
                    {taacImg()}
                    <div className='taac-detail-card-header'>
                        <h2>TAAC {tokenId}</h2>
                        {chakraComponent()}
                    </div>
                    {taacLinks()}
                    <div className='detail-traits-container'>
                        {traitComponent()}
                    </div>
                    {/* <div className='detail-clear-chakra-container'>
                        {clearChakraButton()}
                    </div> */}


                </div> : null
            }

            {showChakraPopup ?
                <ClearChakraPopup
                    className="success-power-chamber-alert"
                    text='Clear your Chakra!'
                    closePopup={onClosePopUp}
                >
                    <div className='clear-chakra-popup'>
                        <div className='clear-chakra-current-xp'>Your current total is <b>{xp}XP</b></div>
                        <div className='clear-chakra-cost'>{xpPerClearance}XP to clear next Chakra level for TAAC #{tokenId}</div>
                        <div className='clear-chakra-img-container'>
                        <img src={getChakraBadge(chakraClearance)}></img>
                            <img src={arrowRight}></img>
                            <img src={getChakraBadge(chakraClearance + 1)}></img>
                        </div>

                        <div className='clear-chakra-button-container'>

                            <Button size="lg" variant="primary" className="custom-btn" onClick={onClosePopUp}>Close</Button>
                            {clearNowButton()}
                        </div>

                    </div>
                </ClearChakraPopup>
                : null
            }

            {successfullyCleared ?
                <ClearChakraPopup
                    className="success-power-chamber-alert"
                    text='Your Chakra is successfully cleared!'
                    closePopup={onClosePopUp}
                >
                    <div className='clear-chakra-popup'>
                        <div className='clear-chakra-current-xp'>New total is {xp}XP</div>
                        <div className='clear-chakra-cost'>Chakra level for TAAC #{tokenId}: <b>{chakraClearance}</b></div>
                        <div className='clear-chakra-img-container'>
                            <img src={getChakraBadge(chakraClearance)}></img>
                        </div>
                        <div className='clear-chakra-button-container-cleared'>
                            <Button size="lg" variant="primary" className="custom-btn" onClick={() => window.location.reload(false)}>Close</Button>
                        </div>

                    </div>
                </ClearChakraPopup>
                : null
            }

            {errorWhileClearing ?
                <ClearChakraPopup
                    className="success-power-chamber-alert"
                    text='There was an error while clearing!'
                    closePopup={onClosePopUp}
                >
                    <div className='clear-chakra-popup'>
                        <div className='clear-chakra-current-xp'>Please close this pop and try again</div>
                    </div>
                    <div className='clear-chakra-button-container-error'>
                            <Button size="lg" variant="primary" className="custom-btn" onClick={() => window.location.reload(false)}>Close</Button>
                        </div>
                </ClearChakraPopup>
                : null
            }
        </div>
    );

}

export default TAACDetail;

