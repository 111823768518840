import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import music from '../music/naruto.mp3'
import playImage from '../img/play-button.png' 
import pauseImage from '../img/pause.png' 

function MusicPlayer() {
    const audioRef = useRef(null);
    const [isPaused, setIsPaused] = useState(true);

    const play = () => {
        isPaused ? audioRef.current.play() : audioRef.current.pause();
        setIsPaused(!isPaused);

        
    };

    const forward = () => {
        audioRef.current.currentTime += 30;
    };

    const backward = () => {
        audioRef.current.currentTime -= 30;
    };

    return (
        <>
        <div className='music-player-component'>
        <audio src={music} loop ref={audioRef}>
                Your browser does not support the audio element.
            </audio>
            <img onClick={play} src={isPaused ? playImage: pauseImage}/>
        </div>
            {/* <button onClick={forward}>Forward</button>
            <button onClick={backward}>Backward</button> */}
        </>
    );
}

export default MusicPlayer;