import React from 'react';
// import { Button } from 'react-bootstrap';
// import { useNavigate } from "react-router-dom"
import logoTwitter from '../img/twitter.png'
import logoOpensea from '../img/logo_opensea.png'

function Content(props) {

    // const navigate = useNavigate()
    // const onClickHandler = () => navigate('mint')

    return <div className='content-component'>
        <div className='content-component-card'>
            <h1 className='content-component-title'>Welcome to TAAC!</h1>
            <div className='content-component-description'>
            <b>The Anime Ape Club </b> is here, with handdrawn and randomly generated Apes ready for a blockchain adventure. Join us on Twitter and don't miss any updates!
            {/* Welcome to <b>THE ANIME APE CLUB!</b> 6666 handdrawn and randomly generated Apes waiting for you...to find a new home on the Ethereum Blockchain. Preparing for the Power Chamber. */}
            </div>
            <div className='content-component-subtitle'></div>
            {/* <a href="https://twitter.com/taacNFT?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false">Follow @taacNFT</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> */}

            <div className="taac-button-container">
                {/* <a target="_blank" href="https://twitter.com/taacNFT" className=" taac-button">
                    <img src={logoTwitter} />
                    Follow
                </a> */}
                {/* <a target="_blank" href="/mint" className=" taac-button-link">
                    Join Allowlist
                </a> */}
                {/* <a target="_blank" href="https://www.theanimeape.club/mint" className=" taac-button-link">
                    Mint now
                </a> */}
                <a target="_blank" href="https://opensea.io/collection/taacnft" className="taac-button-link">
                    <img src={logoOpensea} />
                    Join now
                </a>
            </div>

            
            {/* <Button size="lg" variant="primary" className="custom-btn" onClick={onClickHandler}>Mint now</Button> */}
        </div>
    </div>
}

export default Content;