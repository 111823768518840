import { useRef } from "react";
import {
    useLocation
  } from "react-router-dom";
import logo from '../img/logo_desktop6.png'
import logoMobile from '../img/logo_desktop5.png'
import logoTwitter from '../img/twitter.png'
import logoDiscord from '../img/discord.png'
import logoOpensea from '../img/logo_opensea.png'
import Hamburger from 'hamburger-react'
import '../CSS/App.css';
import Badge from 'react-bootstrap/Badge';
import MusicPlayer from "./MusicPlayer";

import { useState } from "react";



function Navbar() {

    const [isOpen, setOpen] = useState(false)
    const navRef = useRef();

    const location = useLocation().pathname.toLowerCase();

    return (
        <header>
            <div className='img-logo-mobile'>
                <a href='/'>
                    <img src={logoMobile} alt="Logo" />
                </a>
            </div>
            <div className='img-logo'>
                <a href='/'>
                    <img src={logo} alt="Logo" />
                </a>
            </div>
            
            <nav ref={navRef}>
                {/* <a href="/#">Home</a> */}
                {/* <a href="/mint">Mint</a> */}
                
                <a className={location === "/faq" ? "selected-nav": ""} href="/Faq">Faq</a>
                <a className={location === "/mytaacs" ? "selected-nav": ""} href="/mytaacs">My TAACs</a>
                <a className={location === "/powerchamber" ? "selected-nav": ""} href="/PowerChamber">Power Chamber</a>

                <div className="socials">
                    <a href="https://twitter.com/taacNFT" target="_blank">
                        <img src={logoTwitter} alt='Twitter Logo'></img>
                    </a>
                    <a href="https://discord.gg/A5qHQzfAvp" target="_blank">
                        <img src={logoDiscord} alt='Discord Logo'></img>
                    </a>
                    <a href="https://opensea.io/collection/taacnft" target="_blank">
                        <img src={logoOpensea} alt='Opeansea Logo'></img>
                    </a>
                </div>
                <MusicPlayer/>
            </nav>
            <div className='nav-btn-container'>
                <Hamburger color='white' toggled={isOpen} toggle={setOpen} onToggle={toggle => {
                    navRef.current.classList.toggle("responsive_nav");
                }} />
            </div>

        </header>
    );
}

export default Navbar;