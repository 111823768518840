import React from 'react';
import Collapsible from 'react-collapsible';

class Faq extends React.Component {

    render() {
        return (
            <div className='faq'>
                <h1>FAQ</h1>
                <Collapsible easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'} transitionTime={200}  trigger="What is TAAC?" >
                    <p>
                        The Anime Ape Club (TAAC) is a community of fans who love anime, ape, manga and all things related to Japanese pop culture. Our Club offers a unique collection of randomly generated NFTs.
                    </p>
                </Collapsible>
                <Collapsible easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'} transitionTime={200}  trigger="When is the Mint?">
                    <p>
                    First phase is on December 14 at 3 p.m. EST, Mint will debut the Hashira List (HL), and on December 15 at 3 a.m. EST, the Anime List (AL). On December 15 at 3:00 p.m., the Public Mint will begin. Stay tuned for updates on the second phase, which will be decided based on market conditions and in consultation with the community.
                    </p>
                </Collapsible>
                <Collapsible easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'} transitionTime={200}  trigger="What is the supply?">
                    <p>
                        The maximum supply is set to 4000, split in two mint phases.
                    </p>
                </Collapsible>
                <Collapsible easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'} transitionTime={200}  trigger="How to get HL/AL?">
                    <p>
                        Keep up with <a href='https://twitter.com/taacNFT'>@taacNFT</a> to be updated on all HL/AL opportunities!
                    </p>
                </Collapsible>
                <Collapsible easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'} transitionTime={200}  trigger="What is the mint price?">
                    <p>HL: 2 Free, any additional is 0.003ETH</p>
                    <p>AL: 1 Free, any additional is 0.003ETH</p>
                    <p>Public: 0.005ETH</p>
                    <p>To eliminate the bots, there will be no free public mint.</p>
                </Collapsible>

            </div>
        );
    }
}

export default Faq;