import React from 'react';


class ContentImage extends React.Component {

    render() {
        return (
            <div className='content-img-component'>
                <img src={this.props.image} alt="Hero" />
            </div>
        );
    }
}

export default ContentImage;