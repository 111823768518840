import React, { useRef, useEffect, useState } from 'react'
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { storage } from './firebase';
import { ThreeCircles } from 'react-loader-spinner'

const imageCache = {};

const Canvas = props => {

    const [isLoading, setIsLoading] = useState(false);

    const drawToContext = (ctx, image1, image2, image3) => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.resetTransform();
        ctx.beginPath();

        const aspectRatio = image1.width / image1.height;

        // Calculate the width and height to draw the image at, preserving the aspect ratio
        let width = ctx.canvas.width;
        let height = ctx.canvas.height;
        if (ctx.canvas.width / aspectRatio > ctx.canvas.height) {
            width = ctx.canvas.height * aspectRatio;
        } else {
            height = ctx.canvas.width / aspectRatio;
        }

        // Calculate the x and y coordinates to draw the image at, centered on the canvas
        const x = (ctx.canvas.width - width) / 2;
        const y = (ctx.canvas.height - height) / 2;

        ctx.drawImage(image1, 0, 0, image1.width, image1.height, 0, 0, width, height);
        ctx.drawImage(image2, 0, 0, image1.width, image1.height, 0, 0, width, height);
        if(props.speech != "none") {
            ctx.drawImage(image3, 0, 0, image1.width, image1.height, 0, 0, width, height);
        }

        const ctx2 = props.canvas2.current.getContext('2d');
        ctx2.canvas.width = 3200;
        ctx2.canvas.height = 3200;
        ctx2.drawImage(image1, 0, 0, image1.width, image1.height, 0, 0, 3200, 3200);
        ctx2.drawImage(image2, 0, 0, image1.width, image1.height, 0, 0, 3200, 3200);
        if(props.speech != "none") {
            ctx2.drawImage(image3, 0, 0, image1.width, image1.height, 0, 0, 3200, 3200);
        }
    }

    const draw = async (ctx, tokenID, bgID, speechID) => {
        if (tokenID == null || bgID == null) return

        ctx.canvas.width = 500;
        ctx.canvas.height = 500;
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';
        setIsLoading(true);
        let imagesLoaded = 0;

        let image1 = imageCache[bgID];
        if (!image1) {
            const image1Url = await getDownloadURL(ref(storage, `portalBGs/${bgID}.png`));
            image1 = new Image();
            image1.src = image1Url;
            image1.crossOrigin = "anonymous";
            imageCache[bgID] = image1;
        } else {
            imagesLoaded++;
        }

        let image2 = imageCache[tokenID];
        if (!image2) {
            const image2Url = await getDownloadURL(ref(storage, `noBg/${tokenID}.png`));
            image2 = new Image();
            image2.src = image2Url;
            image2.crossOrigin = "anonymous";
            imageCache[tokenID] = image2;
        } else {
            imagesLoaded++;
        }

        const images = [image1, image2];

        let image3 = imageCache[speechID];
        if (speechID != "none") {
            if (!image3) {
                const image2Url = await getDownloadURL(ref(storage, `speech/${speechID}.png`));
                image3 = new Image();
                image3.src = image2Url;
                image3.crossOrigin = "anonymous";
                imageCache[speechID] = image3;
            } else {
                imagesLoaded++;
            }
            images.push(image3);
        }
            
        images.forEach(function (image) {
            if (imagesLoaded >= images.length) {
                setIsLoading(false);
                drawToContext(ctx, image1, image2, image3);
            }
            image.onload = function () {
                imagesLoaded++;
                if (imagesLoaded >= images.length) {
                    setIsLoading(false);
                    drawToContext(ctx, image1, image2, image3);
                }
            }
        });
    }

    useEffect(() => {
        const canvas = props.canvasref.current
        const context = canvas.getContext('2d')
        draw(context, props.tokenid, props.bg, props.speech);
    }, [draw, props.tokenid, props.bg, props.speech])

    return (
        <div className="canvas-container">
            {isLoading &&
                <div className="loading-overlay">
                    <ThreeCircles
                        height="100"
                        width="100"
                        color="#61ad98"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="three-circles-rotating"
                        outerCircleColor=""
                        innerCircleColor=""
                        middleCircleColor=""
                    />
                </div>}
            <canvas ref={props.canvasref} {...props} />
            <canvas id="portal-canvas" ref={props.canvas2} style={{ display: 'none' }} {...props} />
        </div>
    );
}

export default Canvas;