import level1 from '../img/level_1.png'
import level2 from '../img/level_2.png'
import level3 from '../img/level_3.png'

import level1disabled from '../img/level_disabled_1.png'
import level2disabled from '../img/level_disabled_2.png'
import level3disabled from '../img/level_disabled_3.png'


const badgeImages = [level1, level2, level3];
const disabledImages = [level1disabled, level2disabled, level3disabled];

function LevelBadge(props) {

    if (props.level === 0) {
        return (
            <div className='level-batch-component'>
                <h2>Level</h2>
                <div className="level-badge-container">
                    {badgeImages.map((image, index) => (
                        <img
                            src={disabledImages[index]}
                            key={index}
                        />
                    ))}
                </div>
            </div>
        );
    } else {
        return (
            <div className='level-batch-component'>
                <h2>Level</h2>
                <div className="level-badge-container">
                    {badgeImages.map((image, index) => {
                        if (image === null) return
                        return <img
                            src={index < props.level ? image : disabledImages[index]}
                            key={index}
                        />
                    })}
                </div>
            </div>

        );
    }
}

export default LevelBadge;

