import React from 'react';
import Button from 'react-bootstrap/Button';
import plusImg from '../img/add-counter.png';
import minusImg from '../img/remove-counter.png';

class Counter extends React.Component {

    state = {
        count: this.props.min
    }

    componentDidMount = () => {
        this.props.onCounterChange(this.props.min);
    }

    onChangeIncrease = () => {
        if (this.state.count >= this.props.max) return
        this.setState((state) => {
            this.props.onCounterChange(state.count + 1);
            return {count: state.count + 1}
        });
    }

    onChangeDecrease = () => {
        if (this.state.count <= this.props.min) return
        this.setState((state) => {
            this.props.onCounterChange(state.count - 1);
            return {count: state.count - 1}
        });
    }

    render() {
        return (
            <div className='cntr'>
                <Button onClick={this.onChangeDecrease} className='btn-counter'><img src={minusImg} alt='remove'/></Button>
                <div className='cntr-text'>{this.state.count}</div>
                <Button onClick={this.onChangeIncrease} className='btn-counter'><img src={plusImg} alt='add'/></Button>
            </div>
        );
    }
}

export default Counter;