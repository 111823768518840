
import { Web3Button } from "@web3modal/react";
import React, { useState, useEffect } from 'react';
import { CircleSpinnerOverlay } from 'react-spinner-overlay'
import axios from "axios";
import { Button, Alert } from 'react-bootstrap';
import ContentImage from "../Components/ContentImage";
import Counter from "../Components/Counter";
import image from '../img/taac-website.gif';
import PopUp from './Popup'
import { useAccount, useContractReads, chain, useContractRead, usePrepareContractWrite, useContractWrite, useWaitForTransaction, useNetwork } from 'wagmi';
const { ethers, BigNumber } = require("ethers");

let abi = require('./ABI.json');
let contractAddress = "0xD20e1B2a23F48F54A7193b69B7C982E1e1dcd94F";
let contractChainId = 1;

// TODO: Check if correct chain id

const contract = {
    address: contractAddress,
    abi: abi,
}

function readablePrice(price) {
    return ethers.utils.formatEther(price)
}

const saleTitle = (mintState, totalSupply, maxSupply) => {
    if (totalSupply >= maxSupply) {
        return <h1>Sold out!</h1>
    }
    switch (mintState) {
        case 0: return <h1>Mint is currently closed</h1>
        case 1: return <h1>Public Mint (Phase 1)</h1>
        case 2: return <h1>Al Mint (Phase 1)</h1>
        case 3: return <h1>HL Mint (Phase 1)</h1>
    }
}

const supplyLabel = (mintState, totalSupply, maxSupply) => {
    if (mintState == 0 || totalSupply >= maxSupply) return
    return <div className='supply-label'>Supply: {totalSupply.toString()}/{maxSupply}</div>
}

function replaceRange(s, start, end, substitute) {
    return s.substring(0, start) + substitute + s.substring(end);
}


const descriptionLabel = (mintState, totalSupply, maxSupply) => {
    if (mintState == 0 || totalSupply >= maxSupply) return
    switch (mintState) {
        case 1: return <div className='mint-description'><p>TAACemon Mint Event - Capture a TAACball on mint and enter to win a super rare Golden Goku in discord. Whoever has the most TAACballs at the end of the mint will win the super rare Golden Goku!</p><p>P.S. Professor Bak also reduced the mint price to .003 to help you catch more TAACballs.</p></div>
        case 2: return <p className='mint-description'>AL mint is now open! 1 Free mint and for each additional only 0.003ETH (max 10), instead of 0.005ETH</p>
        case 3: return <p className='mint-description'>HL mint is now open! 2 Free mints and for each additional only 0.003ETH (max 10), instead of 0.005ETH</p>
    }
}

const connectedWalletLabel = (mintState, totalSupply, maxSupply, isConnected, address) => {
    if (mintState == 0 || totalSupply >= maxSupply) return
    if (isConnected) {
        return <span className='mint-connected-wallet-text'><b>Wallet:</b> {replaceRange(address, 6, 38, "...")}</span>
    }
}

const priceLabel = (mintState, totalSupply, maxSupply, isConnected, mintPrice, selectedAmount, isWhitelisted, mintedInAL, mintedInOG, userChainID) => {
    if (mintState == 0 || (totalSupply >= maxSupply) || !mintPrice || !isConnected) return
    if (userChainID != contractChainId && userChainID != -1) return
    switch (mintState) {
        case 1: // Public Sale
            var totalPrice = mintPrice.mul(selectedAmount);
            return <p className="mint-price-label">Total Price: {readablePrice(totalPrice)}ETH + Gas</p>
        case 2: // AL Sale
            if (!isWhitelisted) return;
            var remainingFreeMint = mintedInAL > 0 ? 0 : 1;
            var totalPrice = mintPrice.mul(selectedAmount - remainingFreeMint);
            return <p className="mint-price-label">Total Price: {readablePrice(totalPrice)} ETH + Gas</p>;
        case 3: // OG Sale
            if (!isWhitelisted) return;
            if (mintedInOG == 0) {
                var remainingFreeMint = 2;
            } else if (mintedInOG == 1) {
                var remainingFreeMint = 1;
            } else {
                var remainingFreeMint = 0;
            }
            var totalPrice = mintPrice.mul(selectedAmount - remainingFreeMint);
            totalPrice = totalPrice < 0 ? 0 : totalPrice;
            return <p className="mint-price-label">Total Price: {readablePrice(totalPrice)} ETH + Gas</p>;
        default: // Closed Sale -> dont show the label
            return;
    }
}

const successAlert = (successMessage, transactionHash) => {
    if (successMessage != null && transactionHash != null) {
        return <Alert className='alert-error' key='success' variant='success'>
            {successMessage}, <Alert.Link href={`https://${process.env.REACT_APP_ENV == 'dev' ? 'goerli.' : ''}etherscan.io/tx/${transactionHash}`}>view on Etherscan</Alert.Link>
        </Alert>
    }
}

const errorAlert = (mintState, isWhitelisted, mintedInAL, mintedInOG, errorMessage, maxPrivateMint, isTransactionError, writeError, userChainID, isFetchingProof) => {
    var errorAlertMessage = errorMessage;
    if ((mintState == 2 && mintedInAL) || (mintState == 3 && mintedInOG)) {
        if (!isWhitelisted && !isFetchingProof) errorAlertMessage = `You are not in ${mintState == 2 ? "AL": "HL"}, please come back at public mint or buy at secondary`;
        else if (Number(mintState == 2 ? mintedInAL : mintedInOG) >= Number(maxPrivateMint)) {
            errorAlertMessage = `You already reached your mint limit for ${mintState == 2 ? "AL": "HL"}, please wait for public mint or buy at secondary`;
        }
    }
    if (isTransactionError) errorAlertMessage = "Transaction error occured";
    if (writeError) errorAlertMessage = "Transaction incompleted, please try again";

    if (userChainID != contractChainId && userChainID != -1) {
        errorAlertMessage = "Wrong Network please change to Main Ethereum";
    }
    if (errorAlertMessage) return <Alert className='alert-error' key='danger' variant='danger'>{errorAlertMessage}</Alert>
}

function Minting() {
    const [mintPrice, setMintPrice] = useState(BigNumber.from(5000000000000000));
    const [mintState, setMintState] = useState(0);
    const [totalSupply, setTotalSupply] = useState(1);
    const [maxSupply, setMaxSupply] = useState(6666);
    const [selectedAmount, setSelectedAmount] = useState(1);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isWhitelisted, setIsWhitelisted] = useState(false);
    const [mintedInAL, setMintedInAL] = useState(0);
    const [mintedInOG, setMintedInOG] = useState(0);
    const [transactionHash, setTransactionHash] = useState(null);
    const [maxPrivateMint, setMaxPrivateMint] = useState(null);
    const [maxPublicMint, setMaxPublicMint] = useState(null);
    const [ALProof, setALProof] = useState(null);
    const [OGProof, setOGProof] = useState(null);
    const [isFetchingProof, setIsFetchingProof] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    const args = () => {
        if (mintState == 1) {
            return [selectedAmount];
        } else if (mintState == 2) {
            return [selectedAmount, ALProof];
        } else if (mintState == 3) {
            console.log([selectedAmount, OGProof]);
            return [selectedAmount, OGProof];
        }
    }

    const calculatePrice = () => {
        if (!mintPrice && !selectedAmount) return 0
        if (mintState == 1) {
            return mintPrice.mul(selectedAmount);
        } else if (mintState == 2) {
            var remaining = mintedInAL > 0 ? 0 : 1;
            return mintPrice.mul(selectedAmount - remaining);
        } else if (mintState == 3) {
            var remainingFreeMint = 0;
            if (mintedInOG == 0) {
                remainingFreeMint = 2;
            } else if (mintedInOG == 1) {
                remainingFreeMint = 1;
            }
            console.log(selectedAmount);
            return mintPrice.mul(selectedAmount - remainingFreeMint);
        }
    }

    const { config } = usePrepareContractWrite({
        ...contract,
        functionName: mintState == 1 ? 'mint' : 'mintWL',
        args: args(),
        chainId: 1,
        overrides: { value: calculatePrice() }
    });

    const { data: writeData, isLoading: writeIsLoading, isSuccess, write, error: writeError } = useContractWrite({
        ...config,
        onSuccess(data) {
            console.log('Success', data);
            setTransactionHash(data.hash);
        },
    });

    const { address, isConnected } = useAccount();
    const { chain: userChain } = useNetwork();

    const { data, isError: isTransactionError, isLoading } = useWaitForTransaction({
        hash: transactionHash,
        onSuccess(data) {
            setShowSuccessPopup(true);
            // setSuccessMessage('Mint Success');
            setErrorMessage(null);
        },
    }, [transactionHash])

    useEffect(() => {
        if (isConnected) {
            async function fetchData() {
                console.log('fetch for: ', address);
                if (mintState == 2) {
                    setIsFetchingProof(true);
                    const response = await axios.get('https://us-central1-taac-react.cloudfunctions.net/ALProof', { params: { address: address } })
                    if (response.data.proof.length === 0) {
                        setIsWhitelisted(false);
                        setIsFetchingProof(false);
                    } else {
                        setIsWhitelisted(true);
                        setALProof(response.data.proof);
                        setIsFetchingProof(false);
                    }
                } else if (mintState == 3) {
                    setIsFetchingProof(true);
                    const response = await axios.get('https://us-central1-taac-react.cloudfunctions.net/OGProof', { params: { address: address } })
                    if (response.data.proof.length === 0) {
                        setIsWhitelisted(false);
                        setIsFetchingProof(false);
                    } else {
                        setIsWhitelisted(true);
                        setOGProof(response.data.proof);
                        setIsFetchingProof(false);
                    }
                    setSuccessMessage(null);
                }
            }
            fetchData();
        }
    }, [address, mintState]);

    const { isLoading: contractIsLoading, isFetching } = useContractReads({
        contracts: [
            {
                ...contract,
                functionName: 'getMintPrice',
                chainId: chain.goerli.id
            },
            {
                ...contract,
                functionName: 'getMintState',
                chainId: chain.goerli.id
            },
            {
                ...contract,
                functionName: 'maxSupply',
                chainId: chain.goerli.id
            },
            {
                ...contract,
                functionName: 'maxMintWL',
                chainId: chain.goerli.id
            },
            {
                ...contract,
                functionName: 'maxBatchMintPublic',
                chainId: chain.goerli.id
            },
            {
                ...contract,
                functionName: 'totalSupply',
                chainId: chain.goerli.id
            },
            {
                ...contract,
                functionName: 'ALMinted',
                chainId: chain.goerli.id,
                args: [address],
            },
            {
                ...contract,
                functionName: 'OGMinted',
                chainId: chain.goerli.id,
                args: [address],
            }
        ],
        onSuccess(data) {
            setMintPrice(data[0]);
            setMintState(data[1]);
            setMaxSupply(Number(ethers.utils.formatUnits(data[2], 0)));
            setMaxPrivateMint(data[3]);
            setMaxPublicMint(data[4]);
            setTotalSupply(Number(ethers.utils.formatUnits(data[5], 0)));
            setMintedInAL(ethers.utils.formatUnits(data[6], 0));
            setMintedInOG(ethers.utils.formatUnits(data[7], 0));
        },
    });

    const onMint = () => {
        if (mintState > 0) {
            write?.();
        }
    };

    const onCloseMintSuccessPopup = () => {
        window.location.reload(false);
    }

    const mintButton = () => {
        if (mintState == 0 || totalSupply >= maxSupply) return
        // if (userChain?.id != contractChainId && userChain?.id != -1) return
        if (isConnected) {
            if (mintState == 2 && isWhitelisted && (Number(mintedInAL) >= Number(maxPrivateMint))) {
                return
            }
            if (mintState == 3 && isWhitelisted && (Number(mintedInOG) >= Number(maxPrivateMint))) {
                return
            }
            if (((mintState == 2 || mintState == 3) && isWhitelisted) || mintState == 1) {
                return <div className="mint-button-container">
                        <Button size="lg" variant="primary" disabled={isLoading || writeIsLoading} className="custom-btn" onClick={onMint}>{isLoading || writeIsLoading ? "Loading" : "Mint"}</Button>
                        <p className="mint-button-subtitle">Total potential supply 4000 after Phase 2 mint. We reserve the right to reduce the total supply if market conditions warrant it.</p>
                    </div>
            }
        } else {
            return <div className="mint-connect-wallet"><Web3Button /></div>
        }
    }

    const counterComponent = () => {
        if (mintState == 0 || totalSupply >= maxSupply) return
        // if (userChain?.id != contractChainId && userChain?.id != -1) return
        if (isConnected) {
            switch (mintState) {
                case 1: // Public Sale
                    return <Counter onCounterChange={(count) => setSelectedAmount(count)} min={1} max={maxPublicMint} />
                case 2: // AL Sale
                    if (!isWhitelisted || Number(mintedInAL) >= Number(maxPrivateMint)) return;
                    return <Counter onCounterChange={(count) => setSelectedAmount(count)} min={1} max={maxPrivateMint - mintedInAL} />;
                case 3: // OG Sale
                    if (!isWhitelisted || Number(mintedInOG) >= Number(maxPrivateMint)) return;
                    return <Counter onCounterChange={(count) => setSelectedAmount(count)} min={mintedInOG == 0 ? 2 : 1} max={maxPrivateMint - mintedInOG} />;
                default: // Closed Sale
                    return;
            }
        }
    }

    const verifiedContractLink = () => {
        if (mintState == 0) return
        return <div className="contract-link"><a href={`https://etherscan.io/address/${contractAddress}`} target="_blank">Verified Contract</a></div>
    }

    return <div className='mint-page'>
        <CircleSpinnerOverlay
            loading={isLoading || writeIsLoading}
            overlayColor="rgba(252, 203, 244, 0.8)"
            color="rgba(19, 54, 108)"
            message={
                <p style={{ marginTop: "12px", color: "rgba(19, 54, 108)", fontWeight: 800 }}>
                    transaction pending...
                </p>
            }
        />
        <CircleSpinnerOverlay
            loading={contractIsLoading || isFetching}
            overlayColor="rgba(252, 203, 244, 0.8)"
            color="rgba(19, 54, 108)"
            message={
                <p style={{ marginTop: "12px", color: "rgba(19, 54, 108)", fontWeight: 800 }}>
                    Preparing the page for you!
                </p>
            }
        />
        <div className="mint-content">
            <div className="mint-card" >
                {saleTitle(mintState, totalSupply, maxSupply)}
                {supplyLabel(mintState, totalSupply, maxSupply)}
                {descriptionLabel(mintState)}
                {connectedWalletLabel(mintState, totalSupply, maxSupply, isConnected, address)}
                {priceLabel(mintState, totalSupply, maxSupply, isConnected, mintPrice, selectedAmount, isWhitelisted, mintedInAL, mintedInOG, userChain ? userChain.id : -1)}
                {counterComponent()}
                {mintButton()}
                {verifiedContractLink()}
                {showSuccessPopup ?
                    <PopUp
                        text='Mint Success!'
                        closePopup={onCloseMintSuccessPopup}
                    >
                        <div className="mint-success-link">
                            <a href={`https://${process.env.REACT_APP_ENV == 'dev' ? 'goerli.' : ''}etherscan.io/tx/${transactionHash}`} target="_blank">View on Etherscan</a>
                        </div>
                    </PopUp>
                    : null
                }
                {successAlert(successMessage, transactionHash)}
                {errorAlert(mintState, isWhitelisted, mintedInAL, mintedInOG, errorMessage, maxPrivateMint, isTransactionError, writeError, userChain ? userChain.id : -1, isFetchingProof)}
            </div>
        </div>
        <ContentImage image={image} />
    </div>
}

export default Minting;