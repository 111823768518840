import '../custom.scss';
import '../CSS/App.css';
import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from "react-router-dom";

import Home from './Home';
import NotFound from './NotFound';
import WalletStatus from './WalletStatus';
import Faq from './Faq';
import PowerChamber2 from './PowerChamber2';
import UserPage2 from './UserPage2';
import Navbar from './Navbar';
import Minting from './Minting'
import BGPortal from './BGPortal'
import TAACDetail from './TAACDetail';

import {
  EthereumClient,
  modalConnectors,
  walletConnectProvider,
} from "@web3modal/ethereum";

import { Web3Modal } from "@web3modal/react";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";

// const chains = process.env.REACT_APP_ENV == 'dev' ? [chain.mainnet, chain.goerli]: [chain.mainnet];
const chains = [chain.mainnet]

// Wagmi client
const { provider } = configureChains(chains, [
  walletConnectProvider({ projectId: "c9fc66477dc9e340e69c466fe1f0b8e3" }),
]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({ appName: "TAAC", chains }),
  provider,
});

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);

function App() {

  const location = useLocation();

  const appClasses = ['app'];
  if (location.pathname.toLowerCase() === '/powerchamber') {
    appClasses.push('powerchamber-route');
  }

  return (

    <div>
      <div className={appClasses.join(' ')}>

        <Navbar />
        <div className='main'>
          <WagmiConfig client={wagmiClient}>
            <Routes>
              <Route exact path='/' element={<Home />} />
              {/* <Route exact path='/mint' element={<Minting />} /> */}
              <Route exact path='/wallet-status' element={<WalletStatus />} />
              <Route exact path='/Faq' element={< Faq />} />
              <Route exact path='/mytaacs' element={<UserPage2 />} />
              <Route exact path='/PowerChamber' element={< PowerChamber2 />} />
              <Route exact path='/Portal' element={< BGPortal />} />
              <Route path="/detail">
                <Route path=":tokenId" element={<TAACDetail />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </WagmiConfig>

        </div>
        <div className='footer'>
          <div className='footer-copyright'>© 2022 - 2022 The Anime Ape Club - All Rights Reserved.</div>
          <div><a href="mailto:info@theanimeape.club">Contact us info@theanimeape.club</a></div>
        </div>

      </div>
      <Web3Modal
        projectId="c9fc66477dc9e340e69c466fe1f0b8e3"
        theme="dark"
        accentColor="default"
        ethereumClient={ethereumClient}
      />
    </div>

  );
}

export default App;