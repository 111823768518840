import React from 'react';
import image1 from '../img/taac-website.gif'
import Content from '../Components/Content'
import ContentImage from '../Components/ContentImage';

const contentModel = require('../Components/Models/ContentModel.json');

class Home extends React.Component {

    render() {
        return (
            <div className='home'>
                <Content model={contentModel} />
                <ContentImage image={image1} />
            </div>
        );
    }
}

export default Home;