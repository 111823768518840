import { Web3Button } from "@web3modal/react";
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { useAccount, chain, useNetwork } from 'wagmi';
import axios from 'axios';
import { CircleSpinnerOverlay } from 'react-spinner-overlay'
import Canvas from './Canvas'
import { Button, Alert } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import { storage } from './firebase';
import * as firebase from 'firebase/app';

function replaceRange(s, start, end, substitute) {
    return s.substring(0, start) + substitute + s.substring(end);
}

const connectedWalletLabel = (isConnected, address) => {
    if (isConnected) {
        return <span className='mint-connected-wallet-text'><b>Connected:</b> {replaceRange(address, 6, 38, "...")}</span>
    } else {
        return <span className='mint-connected-wallet-text'>No wallet connected</span>
    }
}

const tokenLabel = (isConnected, tokenId) => {
    if (!isConnected) return
    return <p className="portal-token-label">TAAC {tokenId}</p>
}

const connectButton = (isConnected) => {
    if (isConnected) return
    return <Web3Button />
}

const canvasComponent = (isConnected, canvasref, canvas2, tokenId, selectedBG, selectedSpeech) => {
    if (!isConnected) return
    return <Canvas canvasref={canvasref} canvas2={canvas2} className="portal-canvas" tokenid={tokenId} bg={selectedBG} speech={selectedSpeech}/>
}

const onDownload = (fileName) => {
    var canvas = document.getElementById("portal-canvas");
    canvas.toBlob(function (blob) {
        try {
            saveAs(blob, `${fileName}.png`);
        } catch (error) {
            console.log(error);
            const img = new Image();
            img.src = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = img.src;
            a.download = `${fileName}.png`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    });
}

async function getDownloadUrls(folderRef) {
    const fileList = await listAll(folderRef);
    const bgFiles = [];

    for (const file of fileList.items) {
        const downloadUrl = await getDownloadURL(ref(file));
        const fileName = file.name.split('/').pop().split('.')[0];
        bgFiles.push({ downloadUrl: downloadUrl, fileName: fileName });
    }
    return bgFiles;
}

function BGPortal() {

    const [isLoading, setIsLoading] = useState(false);
    const [selectedBG, setSelectedBG] = useState(null);
    const [selectedSpeech, setSelectedSpeech] = useState(null);
    const [downloadBGUrls, setDownloadBGUrls] = useState(null);
    const [downloadSpeechUrls, setDownloadSpeechUrls] = useState(null);

    const { address, isConnected } = useAccount();
    const { chain: userChain } = useNetwork();

    const canvasref = useRef(null);
    const canvas2 = useRef(null);

    const location = useLocation();
    const tokenId = location.state?.tokenId;

    const selectComponent = () => {
        if (!isConnected) return
        if (selectedBG == null) return
        return <div className="portal-select-container">
            <span>Background</span>
            <select className="portal-select" value={selectedBG} onChange={(event) => setSelectedBG(event.target.value)}>
                {downloadBGUrls.map((item, index) => (
                    <option key={index} value={item.fileName}>{item.fileName}</option>
                ))}
            </select>
            <span>Speech Bubble</span>
            <select className="portal-select" value={selectedSpeech} onChange={(event) => setSelectedSpeech(event.target.value)}>
                {console.log(downloadSpeechUrls)}
                {downloadSpeechUrls.map((item, index) => (
                    <option key={index} value={item.fileName}>{item.fileName}</option>
                ))}
            </select>
        </div>
    }

    const buttonComponent = () => {
        if (!isConnected) return
        return <Button size="lg" variant="primary" className="custom-btn" onClick={() => onDownload(selectedBG)}>download</Button>
    }

    const noneSelected = "none"

    useEffect(() => {
        const bgRef = ref(storage, "portalBGs");
        const speechRef = ref(storage, "speech");
        const getBGUrls = async () => {
            const urls = await getDownloadUrls(bgRef);
            setSelectedBG(urls[0].fileName);
            setDownloadBGUrls(urls);
        };
        const getSpeechUrls = async () => {
            var urls = [{downloadUrl: "", fileName: "none"}]
            urls = urls.concat(await getDownloadUrls(speechRef));
            console.log("new urls: ", urls);
            setSelectedSpeech(noneSelected);
            setDownloadSpeechUrls(urls);
        };
        getBGUrls();
        getSpeechUrls();
    }, []);

    return <div className='bg-portal'>
        <CircleSpinnerOverlay
            loading={isLoading}
            overlayColor="rgba(252, 203, 244, 0.8)"
            color="rgba(19, 54, 108)"
            message={
                <p style={{ marginTop: "12px", color: "rgba(19, 54, 108)", fontWeight: 800 }}>
                    is loading...
                </p>
            }
        />
        {/* setSelectedBG(event.target.value) */}
        <div className="bg-portal-container">
            <h1>TAAC Portal</h1>
            <div className="portal-connect-wallet-container">
                {connectedWalletLabel(isConnected, address)}
                {connectButton(isConnected)}
            </div>

            <div className="portal-edit-card">
                <div className="bg-select-container">
                    {selectComponent()}
                </div>
                {tokenLabel(isConnected, tokenId)}
                {canvasComponent(isConnected, canvasref, canvas2, tokenId, selectedBG, selectedSpeech)}
                {buttonComponent()}
            </div>
        </div>
    </div>
}

export default BGPortal;