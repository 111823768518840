import level1 from '../img/db_1.png'
import level2 from '../img/db_2.png'
import level3 from '../img/db_3.png'
import level4 from '../img/db_4.png'
import level5 from '../img/db_5.png'
import level6 from '../img/db_6.png'
import level7 from '../img/db_7.png'

const images = [level1, level2, level3, level4, level5, level6, level7];

function Dragonballs(props) {

    if (props.ballcount === 0) {
        return (
            <div className='level-batch-component'>
                <div className="level-badge-container">
                    {images.map((image, index) => (
                        <img
                            src={image}
                            key={index}
                            className="grayscale-img"
                        />
                    ))}
                </div>
            </div>
        );
    } else {
        return (
            <div className='level-batch-component'>
                <div className="level-badge-container">
                    {images.map((image, index) => {
                        if (image === null) return
                        return <img
                            src={image}
                            key={index}
                            className={index < props.ballcount ? "" : "grayscale-img"}
                        />
                    })}
                </div>
            </div>

        );
    }
}

export default Dragonballs;

