import { Web3Button } from "@web3modal/react";
import React, { useState, useEffect, useCallback } from 'react';
import { useAccount, chain, useNetwork } from 'wagmi';
import axios from 'axios';
import { CircleSpinnerOverlay } from 'react-spinner-overlay'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Button, Alert } from 'react-bootstrap';
import { db } from './firebase';
import { doc, getDoc } from "firebase/firestore";
import ProgressBar from "@ramonak/react-progress-bar";
import auraImage from '../img/electric.gif'
import electricVideo from '../img/electric3.mp4'
import PopUp from './Popup'
import LevelBadge from "./LevelBadge";

const maxXP = 200000;

function replaceRange(s, start, end, substitute) {
    return s.substring(0, start) + substitute + s.substring(end);
}

const barColor = (level, levelData) => {
    if(level === null || levelData === null) return
    switch(level){
        case 0: return "#828282";
        case 1: return levelData.level1.color;
        case 2: return levelData.level2.color;
        case 3: return levelData.level3.color;
    }
}

const fetchUser = async (address) => {
    const docRef = doc(db, "users", address.toLowerCase());
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        console.log(docSnap.data());
        return { xp: docSnap.data().xp, level: docSnap.data().level };
    } else {
        return null
    }
}

// if (process.env.REACT_APP_ENV == 'dev') {
// var baseURL = "http://localhost:5001/taac-react/us-central1/";
// } else {
// var baseURL = "https://us-central1-taac-react.cloudfunctions.net/";
// }

var baseURL = "https://us-central1-taac-react.cloudfunctions.net/"

const connectedWalletLabel = (isConnected, address) => {
    if (isConnected) {
        return <span className='mint-connected-wallet-text'><b>Wallet:</b> {replaceRange(address, 6, 38, "...")}</span>
    }
}

const descriptionLabel = (isConnected, xp) => {
    if (!isConnected) return
    if (xp === null) {
        return <div className='power-chamber-description'>
            <p>Enter the power chamber, collect daily XP and unblock your chakra with each level. Your TAACs must not be listed on OpenSea in order for it to accumulate XP!</p>
        </div>
    } else {
        return <div className='power-chamber-description'>
            <p>Welcome to the Power Chamber, where you can unlock the power of your chakras and strive for enlightenment. As you progress through each level, you'll gain access to new abilities and insights that will help you on your journey. More TAACs means more XP, but be careful - your TAACs must not be listed in order to accumulate XP and advance. Your unlisted TAAC's are now collecting XP in the Power Chamber. Check back in 24-48 hours to see your XP accumulation.</p>
        </div>
    }
}

const progressbar = (isConnected, xp, level, levelData) => {
    if (!isConnected || xp === null || level === null) return
    return <div className="progressbar-component">
        {/* <span className="progress-level">Level {level(xp)}</span> */}
        <LevelBadge level={level} />

        <div className="progressbar-container">
            <img className='aura-img' src={auraImage} alt="Aura" />
            <ProgressBar isLabelVisible={false} labelAlignment="center" animateOnRender={true} bgColor={barColor(level, levelData)} width={"100%"} height={"60px"} completed={xp / maxXP * 100} />
        </div>
        <span style={{ color: barColor(level, levelData) }} className="progress-xp">{xp}XP</span>
    </div>

}

const showErrorMessage = (isEligible, errorMessage, xp) => {
    // if (this.state.accountChainId != chainID) {
    //     return <Alert className='alert-error' key='danger' variant='danger'>
    //         Please switch to the {network} network
    //     </Alert>
    // }

    if (!isEligible) {
        return <Alert className='alert-error' key='danger' variant='danger'>
            Please make sure to hold at least one TAAC to earn Daily XP!
        </Alert>
    } else if (errorMessage != null) {
        return <Alert className='alert-error' key='danger' variant='danger'>
            {errorMessage}
        </Alert>
    }
}

function PowerChamber2() {

    const [fetchingXP, setFetchingXP] = useState(false);
    const [fetchingEligibility, setFetchingEligibility] = useState(false);
    const [fetchingLevelData, setFetchingLevelData] = useState(false);
    const [fetchingUserInfo, setFetchingUserInfo] = useState(false);
    const [userXP, setUserXP] = useState(null);
    const [userLevel, setUserLevel] = useState(null);
    const [isEligible, setIsEligible] = useState(true);
    const [levelData, setLevelData] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);


    const { address, isConnected } = useAccount();
    const { chain: userChain } = useNetwork();

    const onRegister = useCallback(async () => {
        if (!isConnected) return
        let url = `${baseURL}getNonceToSign`
        setFetchingXP(true);
        try {
            let response = await axios.post(url, {
                address: address.toLowerCase()
            });
            console.log(response);
            setFetchingXP(false);
            setErrorMessage(null);
            setShowSuccessPopup(true);
            setUserXP(response.data.xp);
            setUserLevel(response.data.level);
        } catch (err) {
            setUserXP(null);
            setFetchingXP(false);
            setErrorMessage("Failed to Enter the Power Chamber, please try again. If you continue to have this issue please open a ticket in the Discord");
        }
    }, [address, isConnected]);

    const fetch = async () => {
        console.log('fetch:', address);
        setErrorMessage(null);
        setFetchingXP(true);
        let user = await fetchUser(address);
        setUserXP(user?.xp ?? null);
        setUserLevel(user?.level ?? null);
        console.log('fetched:', user?.xp ?? null);
        setFetchingXP(false);
        setFetchingEligibility(true);
        let url = `${baseURL}checkPowerChamberEligibility`
        let response = await axios.get(url, {
            params: {
                address: String(address)
            }
        });
        setFetchingEligibility(false);
        console.log("Is eligible: ", response.data.isEligible);
        setIsEligible(response.data.isEligible);
    }

    const fetchLevelData = async () => {
        let url = `${baseURL}getLevelData`
        setFetchingLevelData(true);
        let response = await axios.get(url);
        setFetchingLevelData(false);
        setLevelData(response.data);
        console.log(response.data);
    }

    const fetchUserInfo = async () => {
        let url = `${baseURL}getDailyUserReward`
        setFetchingUserInfo(true);
        let response = await axios.get(url, {
            params: {
                address: String(address)
            }
        });
        setFetchingUserInfo(false);
        setUserInfo(response.data);
        console.log("user info: ", response.data);
    }


    useEffect(() => {
        if (isConnected) {
            fetch();
            fetchLevelData();
            //fetchUserInfo();
        }
    }, [address]);

    const renderButton = () => {
        // if (this.state.accountChainId != chainID) return
        console.log('User XP: ', userXP);
        if (!isConnected) {
            return <div className="power-chamber-connect"><Web3Button /></div>
        } else if (userXP === null) {
            if (isEligible) {
                return <Button size="lg" variant="primary" className="custom-btn" onClick={onRegister}>Enter Power Chamber</Button>
            }
        }
    }

    const onCloseMintSuccessPopup = () => {
        setShowSuccessPopup(false);
    }

    return (
        <div className='power-chamber'>
            <CircleSpinnerOverlay
                loading={fetchingXP || fetchingEligibility || fetchingLevelData || fetchingUserInfo}
                overlayColor="rgba(252, 203, 244, 0.8)"
                color="rgba(19, 54, 108)"
                message={
                    <p style={{ marginTop: "12px", color: "rgba(19, 54, 108)", fontWeight: 800 }}>
                        is loading...
                    </p>
                }
            />
            <div className='power-chamber-card'>
                <h1 className="power-chamber-h1">Power chamber</h1>
                {connectedWalletLabel(isConnected, address)}
                {descriptionLabel(isConnected, userXP)}
                {progressbar(isConnected, userXP, userLevel, levelData)}
                {renderButton()}
                {showErrorMessage(isEligible, errorMessage)}
                {showSuccessPopup ?
                    <PopUp
                    className="success-power-chamber-alert"
                        text='You entered the Power Chamber!'
                        closePopup={onCloseMintSuccessPopup}
                    >
                        <div >
                            <video  autoPlay muted loop >
                                <source src={electricVideo}/>
                            </video>
                        </div>
                    </PopUp>
                    : null
                }
            </div>
        </div>
    );
}

export default PowerChamber2;