import { Web3Button } from "@web3modal/react";
import React, { useState, useEffect } from 'react';
import { useAccount, chain, useNetwork } from 'wagmi';
import axios from 'axios';
import { CircleSpinnerOverlay } from 'react-spinner-overlay'

const connectedWalletLabel = (isConnected, address) => {
    if (isConnected) {
        return <span className='mint-connected-wallet-text'><b>Connected:</b> {address}</span>
    }
}

const infoLabel = (isConnected) => {
    if (!isConnected) {
        return  <p>Connect your wallet to check your status</p>
    }
}

const walletStatusLabel = (isAL, isOG, isNotWL, isConnected) => {
    if (!isConnected) return
    if (isOG) {
        return <span className="walletStatusLabel">Congrats Hashira! You will be able to mint 2 Free TAACs and for each additional at discounted price!</span>
    } else if (isAL) {
        return <span className="walletStatusLabel">You are in the Anime List (AL), congrats! You will be able to mint 1 Free TAAC and for each additional at discounted price!</span>
    } else if (isNotWL){
        return <span className="walletStatusLabel nonWl">Did't made it yet...please keep in mind that it can take up to 48h after submitting your address!</span>
    }
}

const fetchALProof = async (address) => {
    return await axios.get('https://us-central1-taac-react.cloudfunctions.net/ALProof', { params: { address: address } });
}

const fetchOGProof = async (address) => {
    return await axios.get('https://us-central1-taac-react.cloudfunctions.net/OGProof', { params: { address: address } });
}

function WalletStatus() {

const [isLoading, setIsLoading] = useState(false);
const [isAL, setIsAL] = useState(false);
const [isOG, setIsOG] = useState(false);
const [isNotWL, setIsNotWL] = useState(null);

const { address, isConnected } = useAccount();
const { chain: userChain } = useNetwork();

const fetchStatus = async () => {
    if(!isConnected) return

    setIsLoading(true);
    let response = await Promise.all([
        fetchALProof(address),
        fetchOGProof(address)
    ]);

    setIsLoading(false);
    if (response[1].data.proof.length !== 0) {
        setIsOG(true);
        setIsNotWL(false);
    } else if (response[0].data.proof.length !== 0) {
        setIsAL(true);
        setIsNotWL(false);
    } else {
        setIsOG(false);
        setIsAL(false);
        setIsNotWL(true);
    }
};

useEffect( () => { 
    fetchStatus();
}, [isConnected, address]);

return <div className='wlcheck'>
    <CircleSpinnerOverlay
        loading={isLoading}
        overlayColor="rgba(252, 203, 244, 0.8)"
        color="rgba(19, 54, 108)"
        message={
            <p style={{ marginTop: "12px", color: "rgba(19, 54, 108)", fontWeight: 800 }}>
                is loading...
            </p>
        }
    />
    <div className="wlcheck-card">
    <h1>Wallet Status</h1>
    {infoLabel(isConnected)}
    {walletStatusLabel(isAL, isOG, isNotWL, isConnected)}
    {connectedWalletLabel(isConnected, address)}
    <Web3Button/>
    </div>
</div>
}

export default WalletStatus;